<template>
  <section>
      <div class="img-banner " v-for="(slide) in slides" v-bind:key="slide.sublineSecondLine"
        v-bind:style="{ 'display': '' + slide.display + '', 'background-image': 'url(' + slide.Img + ')' }">
        <div class="example">
          <div class="n2-style font-hover">{{ slide.sublineSecondLine }}</div>
           <a href="https://api.whatsapp.com/send?phone=5519983723072&amp;text=Olá%20Gtx%20Guincho,%20gostaria%20de%20um%20orçamento." target="_blank" class="btn-banner" rel="noopener noreferrer"><span>SOLICITE UM GUINCHO 24 HORAS</span></a>
        </div>
      </div>
      <!-- Dispomos de profissionais treinados e qualificados pela nossa empresa estamos disponíveis 24 horas por dia em todas as regiões de São Paulo, interior e litoral. Nós trabalhamos com foco total para um melhor atendimento aos nossos clientes. -->
  </section>
</template>
<style scoped lang="scss">
*{
  overflow: hidden;
}
section{
  margin-bottom: 3rem!important;
}
.example {
  background: none;
  display: -moz-box; /* Mozilla */
  display: -webkit-box; /* WebKit */
  height: 300px;
  /* Children should be oriented vertically */
  -moz-box-orient: vertical; /* Mozilla */
  -webkit-box-orient: vertical; /* WebKit */
  /* Align children to the horizontal center of this box */
  -moz-box-align: center; /* Mozilla */
  -webkit-box-align: center; /* WebKit */
  /* Pack children to the bottom of this box */
  -moz-box-pack: end; /* Mozilla */
  -webkit-box-pack: end; /* WebKit */
  div{
    margin:2rem!important;
  }
  padding: 2rem 0!important;
}
/*  */
.b {
  display: grid;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.c {
  position: absolute;
  bottom: 0;
  right: 10%;
  left: 10%;
  display: none;
}
.img-banner {
  max-width: 100%!important;
  min-height: 500px!important;
  height:auto!important;
 background-size: 100% 100%;
  @media only screen and (max-width: 600px) {
    background-size:auto
  }
  background-repeat: no-repeat;
}
.div-banner {
  position: absolute;
  bottom: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slideteste {
  transition: 'transform 0.4s ease';
  transform: 'translateX(0%)';
}
.n2-style {
  background: #111111;
  opacity: 0.7;
  box-shadow: none;
  border-width: 0px;
  border-style: solid;
  border-color: #000000;
  border-color: RGBA(0, 0, 0, 1);
  border-radius: 0px;
  width: 90%;
  padding: 2rem!important;
}
.font-hover {
  font-family: 'Roboto', 'Arial';
  color: #ffffff;
  font-size: 225%;
  text-shadow: none;
  line-height: 1.5;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
}
</style>
<script>
export default {
  name: 'Banner',
  data () {
    return {
      currentSlide: 0,
      isPreviousSlide: false,
      isFirstLoad: true,
      slides: [
        {
          sublineSecondLine: 'Precisa de um guincho em Indaiatuba e Região? Nós temos a solução perfeita para você! Com atendimento rápido e preços justos!',
          Img: require('@/assets/banner01.jpg'),
          display: 'none'
        },
        {
          sublineSecondLine: 'Tendo problemas com o seu veículo? Não se preocupe, nosso serviço de guincho está aqui para ajudar!',
          Img: require('@/assets/banner02.jpg'),
          display: 'block'
        }
      ]
    }
  },
  mounted () {
    this.nextSlide()
  },
  methods: {
    nextSlide () {
      for (const x in this.slides) {
        if (this.slides[x].display === 'none') {
          this.slides[x].display = 'block'
        } else {
          this.slides[x].display = 'none'
        }
      }
      setTimeout(this.nextSlide, 7000)
    }
  }
}
</script>
