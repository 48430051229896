<template>
  <footer>
    <a
      href="https://api.whatsapp.com/send?phone=5519983723072&amp;text=Olá%20Gtx%20Guincho,%20gostaria%20de%20um%20orçamento."><button
        class="btn-whats"><img width="32" height="32" alt="Whatsapp" src="@/assets/whatsapp-32.svg"></button></a>
        <ul>
          <li>
            <div>
              <!-- <img width="176" height="75" src="@/assets/logo.png" alt="IJR mudanças e carreto"> -->
              <p>
                A <strong>GTX Guincho</strong> é uma empresa especializada em serviços de reboque que utiliza uma abordagem única, unindo a experiência com a inovação, para fornecer aos seus clientes um serviço de qualidade excepcional, que atende perfeitamente às suas necessidades. Estamos comprometidos em superar as expectativas dos nossos clientes e em fornecer soluções rápidas e confiáveis para qualquer situação de emergência ou problema com veículos. Conte conosco para ajudá-lo a superar qualquer desafio na estrada!
              </p>
              <p><a href="tel:19983723072"><span class="highlight thin "><img width="16" height="16" alt="Whatsapp" src="@/assets/phone-32.svg">&nbsp;&nbsp;<strong>(19)&nbsp;98372-3072</strong></span></a></p>
              <a href="mailto:contato@gtxguincho.com.br"><span><img src="@/assets/envelope-32.svg" width="16"
                    height="16">&nbsp;contato@gtxguincho.com.br</span></a>
              <div class="footer-icons">
                <a href="https://www.instagram.com/gtx_guincho/"><span><img src="@/assets/instagram-logo-32.svg"
                      width="32" height="32"></span></a>&nbsp;
                <a href="https://www.facebook.com/gtx_guincho/"><span><img src="@/assets/facebook-logo-32.svg" width="32"
                      height="32"></span></a>
              </div>
            </div>
          </li>
          <li>
            <h3><strong>INSTITUCIONAL</strong></h3>
            <router-link to="/">Home</router-link>
            <router-link to="/sobre">Sobre</router-link>
            <router-link to="/contato">Contato</router-link>
          </li>

          <li>
            <h3><strong>SERVIÇOS</strong></h3>
            <router-link to="/reboque">Reboque</router-link>
            <router-link to="/troca-de-pneus">Troca de pneus</router-link>
            <router-link to="/carga-na-bateria/">Carga na bateria</router-link>
            <router-link to="/pane-seca/">Pane seca</router-link>
          </li>

        </ul>
    <div class="copy">
      <p>Copyright @ <strong class="year"> {{ year }}</strong> - Todos os direitos reservados</p>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
  data: function () {
    return {
      year: ''
    }
  },
  created () {
    this.year = new Date().getFullYear()
  }
}
</script>
<style scoped lang="scss">
$c_20d366: #28a745;
$n: none;
// $abs:absolute;
// $p_100:100%;
// a{
//   padding: 1rem!important;
// }
.year {
  color: #C92F63 !important
}
footer {
  padding:0!important;
  background: #171717;
  * {
    color: #585858 !important;
    text-align: left !important;
    overflow: hidden !important;
    margin-bottom: .5rem !important
  }
  h3 {
    color: #fff
  }
  ul {
    max-width: 90%!important;
    display: grid;
    column-gap: 5rem;
    grid-template-columns: repeat(3,calc(100%/3));
    @media only screen and (max-width: 600px) {
    max-width: 100%!important;
    grid-template-columns: repeat(1,100%);
  }
    padding: 0!important;
    margin: 0 auto!important;
    li{
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: flex-start;
      @media only screen and (max-width: 600px) {
        padding: 1rem!important;
  }
      &:nth-child(1)
        {
          background: #111111;
          padding: 1rem!important;
        }
        * {
      margin: .5rem 0 !important;
    }
    }

  }
  border-top: solid .5rem #313133;
  padding-top: 2rem !important;
  strong {
    color: #e2e2e2 !important
  }
  .copy {
    p {
      margin: 0 !important;
    }
    background: #111111;
    padding: 1rem !important;
    margin: 0 !important;
  }
}
.btn-whats {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 40px;
  left: 93% !important;
  @media only screen and (max-width: 600px) {
    left: 75% !important
  }
  z-index: 6;
  background: $c_20d366;
  border-radius: 1000px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: $n;
  color:$n;
}
/* .btn::before {
  content: '';
  border-radius: 100px;
  min-width: calc(70px + 6px);
  min-height: calc(70px + 6px);
  border: 3px solid $c_20d366;
  position: $abs;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
} */
.btn:hover,
.btn:focus {
  color: #313133;
  transform: translateY(-6px);
}
/* .btn:hover::before,
.btn:focus::before {
  opacity: 1;
}
.btn::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: $p_100;
  border: 6px solid $c_20d366;
  position: $abs;
  z-index: -1;
  top: 50%;
  left: 50%;
 transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
} */
.btn:hover::after,
.btn:focus::after {
  animation: none;
  display: none;
}
@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0
  }
}
span img {
  vertical-align: middle;
}
</style>
