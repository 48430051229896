<template>
  <nav class="navbar">
    <div class="logo"><img src="@/assets/logo.jpg" alt="LOGO"></div>
    <div class="push-left">
      <button id="menu-toggler" v-on:click="toggleBodyClass('menu-active')" data-class="menu-active" class="hamburger">
        <span class="hamburger-line hamburger-line-top"></span>
        <span class="hamburger-line hamburger-line-middle"></span>
        <span class="hamburger-line hamburger-line-bottom"></span>
      </button>

      <!--  Menu compatible with wp_nav_menu  -->
      <ul id="primary-menu" class="menu nav-menu" v-bind:class="active" v-on:click.prevent>
        <li class="menu-item current-menu-item"><router-link to="/" class="nav__item home" v-on:click="makeActive('home')">HOME</router-link></li>
        <li class="menu-item dropdown"><router-link to="/about" class="nav__item about" v-on:click="makeActive('about')">SOBRE NÓS</router-link></li>
        <li class="menu-item dropdown"><a class="nav__link servicos" v-on:click="makeActive('servicos')">SERVIÇOS</a>
          <ul class="sub-nav">
            <li><router-link to="/reboque" class="sub-nav__link reboque" v-on:click="makeActive('reboque')">Reboque</router-link></li>
            <li><router-link to="/troca-de-pneus" class="sub-nav__link pneus" v-on:click="makeActive('pneus')">Troca de pneus</router-link></li>
            <li><router-link to="/carga-na-bateria" class="sub-nav__link bateria" v-on:click="makeActive('bateria')">Carga na bateria</router-link></li>
            <li><router-link to="/pane-seca" class="sub-nav__link pane" v-on:click="makeActive('pane')">Pane seca</router-link></li>
          </ul>
        </li>
        <li class="menu-item "><router-link to="/galeria" class="nav__item galeria" v-on:click="makeActive('galeria')">GALERIA</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>
<style scoped lang="scss" >
/* Extra */
$primary: #fff;
$secondary: #ccc;
$ternary: #272727;
$dark: #444444;
$accent: #C92F63;
$easing: cubic-bezier(0.25, 0.46, 0.45, 0.94);
.nav__item {
  padding: .75rem 1rem;
  color: $primary;
  transition: color .3s $easing;
}
.menu {
  &.home .home,
  &.galeria .galeria,
  &.about .about,
  &.servicos .servicos,
  &.reboque .reboque,
  &.pneus .pneus,
  &.bateria .bateria,
  &.pane .pane,
  &.contact .contact {
    color: $accent;
  }
}

.logo img {
  max-width: 10rem!important;
  width: auto!important;
  height: auto!important;
}

.navbar {
  align-items: center;
  background: $primary;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  font-family: sans-serif;
  padding: 10px 50px;
}

.push-left {
  margin-left: auto;
}

/* Menu */
.hamburger {
  background: transparent;
  border: none;
  cursor: pointer;
  display: none;
  outline: none;
  height: 30px;
  position: relative;
  width: 30px;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    display: inline-block;
  }

  &-line {
    background: $ternary;
    height: 3px;
    position: absolute;
    left: 0;
    transition: all .2s ease-out;
    width: 100%;

    .hamburger:hover & {
      background: #777;
    }

    &-top {
      top: 3px;
    }

    .menu-active &-top {
      top: 50%;
      transform: rotate(45deg) translatey(-50%);
    }

    &-middle {
      top: 50%;
      transform: translatey(-50%);
    }

    .menu-active &-middle {
      left: 50%;
      opacity: 0;
      width: 0;
    }

    &-bottom {
      bottom: 3px;
    }

    .menu-active &-bottom {
      bottom: 50%;
      transform: rotate(-45deg) translatey(50%);
    }
  }
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: all .25s ease-in;
  z-index: 2;
  @media screen and (max-width: 768px) {
    background: $primary;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translatey(-100%);
    text-align: center;

    .menu-active & {
      transform: translatey(0%);
      opacity: 1;
    }
  }

  .menu-item a {
    color: $dark;
    display: block;
    line-height: 30px;
    margin: 0px 10px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      margin: 8px;
    }
  }
}
.sub-nav li a{
  text-align: left;
}
.sub-nav {
  border: 1px solid #ccc;
  display: none;
  position: absolute;
  background-color: $primary;
  padding: 5px 5px;
  list-style: none;
  width: 230px;

  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    display: none;
    background-color: rgba(0, 0, 0, 0.20);
    box-sizing: border-box;
  }
}

.nav__link {
  padding: .75rem 1rem;
  &:hover+.sub-nav {
    display: block;

  }
}

.sub-nav {
  &:hover {
    display: block;
  }
}
</style>
<script>
export default {
  name: 'Home',
  data () {
    return {
      active: 'home'
    }
  },
  methods: {
    makeActive: function (item) {
      if (item !== 'servicos' && item !== 'pneus') {
        document.body.classList.toggle('menu-active')
      }
      this.active = item
    },
    toggleBodyClass (className) {
      document.body.classList.toggle(className)
    }
  }
}
</script>
