<template>
  <Nav/>
  <section class="r-vw">
  <router-view/>
</section>
  <Footer/>
</template>
<style lang="scss">
.btn-banner {
  position: relative;
  margin: 1rem!important;
  background: #C92F63;
  color:#fff;
  opacity: 1;
  padding: 10px 30px 10px 30px!important;
  border-radius: 99px;
  width: 20%;
  @media only screen and (max-width: 600px) {
    width: 50%
  }
}
.r-vw{
  margin:7rem 0!important;
}
*{
  list-style: none!important;
  text-decoration: none!important;
  max-width: 100vw!important;
  overflow-x: hidden!important;
  box-sizing: border-box!important;
  border: 0!important;
}
li{
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Nav,
    Footer
  }
}
</script>
