<template>
  <div class="home">
    <Banner/>
    <Start/>
  </div>
</template>
<script>
import Banner from '@/components/Banner.vue'
import Start from '@/components/Start.vue'
export default {
  name: 'Home',
  components: {
    Banner,
    Start
  }
}
</script>
