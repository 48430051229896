<template>
  <section class="g3">
  <ul>
    <li><span class="title"> TE ATENDEMOS </span><span class="text"> <strong>EM 20 </strong> MINUTOS </span></li>
    <li class="skew"><h2 class=" section_header ">  <a href="tel:19983723072"><span class="highlight thin "><img width="32" height="32" alt="Whatsapp" src="@/assets/phone-32.svg">&nbsp;<strong>(19)&nbsp;98372-3072</strong></span></a> </h2></li>
    <li><div class="fw-column-inner padding_20"> <div class="fw-divider-space " style="margin-top:5px;"></div> <div class="media small-teaser shortcode-icon nitro-offscreen"> <div class="media-left"> <div class="icon-wrap"> <i class="toyicon-phone black fontsize_18"></i> </div> </div> <div class="media-body"> <span class="title"> <strong>PROFISSIONAIS DISPONÍVEIS</strong> </span> <span class="text"> 24 HORAS POR DIA </span> </div> </div> <span class="theme-icon bg_icon icon_left"> <i class="toyicon-phone default_icon"></i> </span> </div></li>
  </ul>
</section>
  <section class="g4">
    <div>
      <h2>Serviços 24 horas por dia 7 dias por semana.</h2>
      <h4>Ligou, chamou, chegou</h4>
      <a href="https://api.whatsapp.com/send?phone=5519983723072&amp;text=Olá%20Gtx%20Guincho,%20gostaria%20de%20um%20orçamento." target="_blank" class="btn-banner" rel="noopener noreferrer"><span>PEÇA UM ORÇAMENTO</span></a>
    </div>
  <ul>
    <li>
      <img src="@/assets/pneu.webp" alt="pneu" style="width: 94px; height: 93px; object-fit: cover; object-position: 50% 50%;" fetchpriority="high">
      <span style="font-family:helvetica-w01-bold, helvetica-w02-bold, helvetica-lt-w10-bold, sans-serif; letter-spacing:0.03em;" class="wixui-rich-text__text">Guincho</span>
    </li>
    <li>
      <img src="@/assets/icon_caminhao.webp" alt="icon_caminhao" style="width: 188px; height: 188px; object-fit: cover; object-position: 50% 50%;" fetchpriority="high">
      <span style="font-family:helvetica-w01-bold, helvetica-w02-bold, helvetica-lt-w10-bold, sans-serif; letter-spacing:0.03em;" class="wixui-rich-text__text">Troca de pneus</span>
    </li>
    <li>
      <img src="@/assets/bateria-de-carro.webp" alt="bateria-de-carro" style="width: 94px; height: 93px; object-fit: cover; object-position: 50% 50%;" fetchpriority="high">
      <span style="font-family:helvetica-w01-bold, helvetica-w02-bold, helvetica-lt-w10-bold, sans-serif; letter-spacing:0.03em;" class="wixui-rich-text__text">Carga na bateria</span>
    </li>
    <li>
      <img src="@/assets/galao.webp" alt="galao" style="width: 84px; height: 84px; object-fit: cover; object-position: 50% 50%;" fetchpriority="high">
      <span style="font-family:helvetica-w01-bold, helvetica-w02-bold, helvetica-lt-w10-bold, sans-serif; letter-spacing:0.03em;" class="wixui-rich-text__text">Pane seca</span>
    </li>
  </ul>
</section>
</template>
<style lang="scss" scoped>
*{
  overflow: hidden;
}
.g3{
  ul{
    padding:0!important;
    margin:0!important;
  display: grid;
  grid-template-columns: repeat(3,calc(100%/3));
  @media only screen and (max-width: 600px) {
    max-width: 100%!important;
    grid-template-columns: repeat(1,100%);
  }
  background: #C92F63;
  *{
    color:#fff;
  }
  li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .skew {
    background: #111111;
    h2 span a strong{
      color:#C92F63!important;
    }
  padding: 10px;
  -webkit-transform: skew(-30deg);
      -ms-transform: skew(-30deg);
          transform: skew(-30deg); /* SKEW inverso para o texto não inclinar */
          @media only screen and (max-width: 600px) {
            -webkit-transform: none;
      -ms-transform: none;
          transform: none; /* SKEW inverso para o texto não inclinar */
  }
}
}
}
.g4{
  div{
    padding:1rem 0!important;
  *{
      margin:2rem 0!important;
    }
  }
  ul{

  display: grid;
  grid-template-columns: repeat(4,calc(100%/4));
  @media only screen and (max-width: 600px) {
    max-width: 100%!important;
    grid-template-columns: repeat(1,100%);
  }
  li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:1rem 0!important;
  *{
      margin:2rem 0!important;
    }
  }
}
}
span img{
  vertical-align: middle;
}
</style>
